* {
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  letter-spacing: 0.012rem;
  word-spacing: 0.016rem;
}

/* #0f8b54 */
:root {
  --primary-color: #0f8b54; /* Define the variable here */
}

.primaryShadow {
  border: none !important;
  box-shadow: 0 0 10px rgba(15, 139, 84, 0.3);
}
.primaryBorder {
  border: var(--primary-color) 2px solid !important;
}
.primaryColor {
  color: var(--primary-color) !important;
}
.primaryBackground {
  background-color: var(--primary-color) !important;
}

.logoImg {
  height: 2rem;
}

.cardImg {
  height: 200px !important;
  object-fit: contain !important;
  width: auto;
  padding-top: 1rem;
}

/* Default state (when collapsed) */
.accordion-button {
  outline: none !important;

  background-color: #f8f9fa;
  color: #333;
  border: none;
}

/* Expanded state */
.accordion-button:not(.collapsed) {
  background-color: var(
    --primary-color
  ) !important; /* Change to your preferred color */
  color: #fff !important;
}

/* Focus state (when the button is focused or clicked) */
.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid var(--primary-color) !important; /* Add a focus border or change this to whatever you like */
}

.hero {
  position: relative;
  height: 100vh;
  background-image: url("./assets/bg1.jpg");
  background-size: cover;
  background-position: left;
  color: white;
  overflow: hidden;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero > div {
  position: relative;
  z-index: 2; /* Ensures the text is above the overlay */
}

.hero h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero p {
  color: black;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  .hero h1 {
    font-size: 4rem;
  }
  .hero p {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .hero h1 {
    font-size: 5rem;
  }
  .hero p {
    font-size: 1rem;
  }
}

.subSection {
  padding-top: 7rem;
  padding-bottom: 3rem;
}

.subSection img {
  width: 100%;
  height: auto; /* Ensure the image maintains its aspect ratio */
  overflow: hidden;
}

.subSection h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
}

/* .subSection p {
  font-size: 1rem;
  line-height: 1.2;
} */

.how-it-works-section {
  background-color: #68b1ea;
  padding: 50px 0;
}

.how-it-works-section h2 {
  color: white;
}

.how-it-works-section p,
.how-it-works-section li {
  color: white;
}

.contact-us-section {
  background-color: #f8f9fa; /* Light grey background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-section h2 {
  color: #343a40; /* Dark grey text */
}

.contact-us-section p {
  color: #343a40; /* Dark grey text */
}

.centered-text {
  margin: 0 auto;
  text-align: center;
}
